










import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
@Component
export default class InsDrawer extends Vue {
    @Prop() private show!:boolean;
    @Prop() private drawerChange!:boolean;
    @Watch('show')
    onShowChange () {
      if (this.show === true) { setTimeout(() => { document.body.style.overflowY = 'hidden'; }, 50); } else document.body.style.overflowY = 'auto';
    }
}
